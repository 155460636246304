import logo from './langos-green-orange-shield.png';
import './App.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = "Lango's Market";
  });
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
